/*
 * @Author:
 * @Date: 2022-07-18 10:57:11
 * @LastEditTime: 2022-12-09 10:23:00
 * @Description:
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Routes, Route, BrowserRouter, useLocation, Navigate } from 'react-router-dom';
import React, { Suspense, lazy, FC, ReactElement, useState, useEffect } from 'react';
import Login from '../Login';
import { AnimatePresence } from 'framer-motion';
import { UserInfoApi } from '../request/api';

const App = lazy(() => import(/* webpackChunkName: "app" */ '../App'));
const Register = lazy(() => import(/* webpackChunkName: "register" */ '../Register'));
const Course = lazy(() => import(/* webpackChunkName: "course" */ '../pages/CoursePage'));
const Database = lazy(() => import(/* webpackChunkName: "database" */ '../pages/DatabasePage'));
const Dbdownload = lazy(() => import('../pages/DatabasePage/DatabaseDL'));
const Product = lazy(() => import(/* webpackChunkName: "product" */ '../pages/ProductPage/index'));
const Productdetail = lazy(() => import('../pages/ProductPage/ProductDetail'));

interface Iroute {
    path: string;
    component: FC;
    children?: Iroute[];
}
interface IRes {
    errCode?: number;
    message?: string;
    data?: any;
}

const routeArr: Iroute[] = [
    { path: '/index', component: App },
    { path: '/course', component: Course },
    { path: '/database', component: Database },
    { path: '/dbdownload', component: Dbdownload },
    { path: '/product', component: Product },
    { path: '/productdetail', component: Productdetail },
];

const AnimateRoutes = () => {
    const location = useLocation();

    UserInfoApi().then((res: IRes) => {
        if (res.errCode == 0) {
            localStorage.setItem('username', res.data.username);
            localStorage.setItem('tk-auth-token', res.data['tk-auth-token']);
            localStorage.setItem('avatar', res.data.avatar);
            localStorage.setItem('logintype', 'true');
        } else {
            localStorage.setItem('username', null);
            localStorage.setItem('tk-auth-token', null);
            localStorage.setItem('avatar', null);
            localStorage.setItem('logintype', 'false');
        }
    });

    // console.log(result);
    // console.log(localStorage.getItem('logintype'));

    return (
        <AnimatePresence exitBeforeEnter>
            <Routes location={location} key={location.pathname}>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route path="/" element={<Login />}></Route>
                {localStorage.getItem('logintype') == 'false'
                    ? routeArr.map((item, index) => (
                          <Route key={index} path={item.path} element={<Navigate to="/login" />}></Route>
                      ))
                    : routeArr.map((item, index) => (
                          <Route key={index} path={item.path} element={<item.component />}></Route>
                      ))}
            </Routes>
        </AnimatePresence>
    );
};

const MyRouter: FC = (): ReactElement => {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>loading...</div>}>
                <AnimateRoutes />
            </Suspense>
        </BrowserRouter>
    );
};

export default MyRouter;
