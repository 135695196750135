/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'amfe-flexible'; //移动端适配
import MyRouter from './router';
import { Provider } from 'react-redux';
import store from './store';

import './App.less';

const render = (Component: React.FC): void => {
    const container = document.getElementById('root');
    createRoot(container).render(
        <Provider store={store}>
            <React.StrictMode>
                <Component />
            </React.StrictMode>
        </Provider>,
    );
};

render(MyRouter);

if ((module as any).hot) {
    (module as any).hot.accept('./App.tsx', () => {
        render(MyRouter);
    });
}
